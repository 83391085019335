<template>
  <v-list-item
    v-if="!do_not_show"
    :link="location.items.length !== 0"
    @click="location.items.length !== 0 ? details_open = !details_open : false"
    ripple
  >
    <v-list-item-content ripple v-if="location.items.length >= 1">
      <v-list-item-title>{{ getLocationTime(location) }}</v-list-item-title>
      <v-list-item-subtitle>{{ location.data.address }}</v-list-item-subtitle>
      <div
        class="point_details"
        v-if="details_open"
      >{{ $t("today.total_locations_at_this_point") }}: {{ location.items.length }}</div>
    </v-list-item-content>
    <div v-else>
      <i class="fal fa-ellipsis-v"></i>
      <span style="margin-left:20px;">
        {{ moment("2015-01-01").startOf('day')
        .seconds(getDuration())
        .format('HH:mm') }}h
        <span style="color:grey;">{{ $t("timeline.on_the_way").toLowerCase() }}</span>
      </span>
    </div>
    <v-list-item-action v-if="location.items.length !== 0">
      <i v-if="!details_open" class="fad fa-chevron-down"></i>
      <i v-if="details_open" class="fad fa-chevron-up"></i>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    full_locations: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      details_open: false,
      do_not_show: false
    };
  },
  methods: {
    parseTime(timestamp) {
      var date = new Date(parseInt(timestamp) * 1000);
      return (
        (date.getHours() + "").padStart(2, "0") +
        ":" +
        (date.getMinutes() + "").padStart(2, "0")
      );
    },
    getLocationTime(location) {
      var start_time = this.parseTime(location.data.time);
      var end_time;
      if (location.items.length > 0) {
        let last_point = location.items[location.items.length - 1];
        end_time = this.parseTime(last_point.data.time);
      }

      if (end_time !== undefined) {
        return start_time + " - " + end_time;
      }
      return start_time;
    },

    getPreviousPoint(step) {
      if (step === undefined) {
        step = 0;
      }
      return this.full_locations[this.index <= 0 ? 0 : this.index - 1 - step];
    },

    getNextPoint(step) {
      if (step === undefined) {
        step = 0;
      }
      return this.full_locations[
        this.index >= this.full_locations.length
          ? this.full_locations.length - 1
          : this.index + 1 + step
      ];
    },

    // If it is a waypoint
    getDuration() {
      var next_step = 0;
      var prev_step = 0;
      while (this.getNextPoint(next_step).items.length === 0) {
        next_step++;
        if (next_step > 200) {
          break;
        }
      }

      while (this.getPreviousPoint(prev_step).items.length === 0) {
        prev_step++;

        if (prev_step > 200) {
          break;
        }
      }

      if (prev_step > 0) {
        // Do not show this point group, when it already has been showed before.
        this.do_not_show = true;
      }

      var last_previous_point = this.getPreviousPoint(prev_step).items[
        this.getPreviousPoint(prev_step).items.length - 1
      ];
      var first_next_point = this.getNextPoint(next_step); //Do not use the item from the items array, since the index 0 is on the root object.

      var first_time = first_next_point.data.time;
      var last_time = last_previous_point.data.time;

      return first_time - last_time;
    }
  }
};
</script>

<style>
.point_details {
  padding-top: 10px;
}
</style>