<template>
  <div>
    <div
      v-if="!loaded || l_day_deletion"
      class="empty_map"
      style="text-align: center"
    >
      <v-progress-circular
        :indeterminate="true"
        size="50"
        style="margin-top: 125px"
      ></v-progress-circular>
    </div>
    <div v-else>
      <div v-if="currentView === 'points'">
        <GmapMap
          v-if="locations.length !== 0"
          :center="map_center"
          :zoom="map_zoom"
          map-type-id="roadmap"
          style="width: calc(100% + 2 * 12px); height: 400px; margin: -12px"
        >
          <gmap-cluster>
            <GmapMarker
              v-for="location in pointLocations"
              :position="google && new google.maps.LatLng(location.location)"
              :key="location.id"
            ></GmapMarker>
          </gmap-cluster>
        </GmapMap>
        <div class="empty_map" v-else></div>
      </div>
      <div v-else>
        <GmapMap
          v-if="locations.length !== 0"
          :center="map_center"
          :zoom="map_zoom"
          map-type-id="roadmap"
          style="width: calc(100% + 2 * 12px); height: 400px; margin: -12px"
        >
          <GmapPolyline
            :path.sync="path"
            :options="{ strokeColor: '#000' }"
          ></GmapPolyline>
        </GmapMap>
        <div class="empty_map" v-else></div>
      </div>
    </div>
    <v-layout style="margin: 25px 0 30px 0" row>
      <v-flex sm8 xs8 style="text-align: left">
        <h3>
          {{ view_date }}
          <i
            style="margin-left: 5px; cursor: pointer"
            @click="d_date_select = true"
            class="fad fa-calendar-day"
          ></i>
        </h3>
      </v-flex>
      <v-flex sm4 xs4 style="text-align: right">
        <v-btn @click="dayBack" text>
          <i class="fal fa-chevron-left"></i>
        </v-btn>
        <v-btn
          @click="dayForward"
          :disabled="today_date === programmatically_date"
          text
        >
          <i class="fal fa-chevron-right"></i>
        </v-btn>
      </v-flex>
    </v-layout>
    <div v-if="!loaded || l_day_deletion" style="text-align: center">
      <v-progress-circular
        v-if="!loaded"
        :indeterminate="true"
        size="70"
        style="margin-top: 30px"
      ></v-progress-circular>
    </div>
    <div v-else>
      <div v-if="locations.length === 0" style="text-align: center">
        <p>{{ $t("timeline.no_data_recorded_for_this_date_yet") }}</p>
        <p>{{ $t("timeline.install_the_companion") }}</p>
      </div>
      <div v-else>
        <v-list
          style="background: transparent"
          v-if="currentView === 'grouped'"
        >
          <PointGroup
            v-for="(location, index) in locations_filtered"
            :key="location.id"
            :location="location"
            :full_locations="locations_filtered"
            :index="index"
          ></PointGroup>
        </v-list>
        <v-list style="background: transparent" v-else>
          <PointSingle
            v-for="(location, index) in locations"
            :key="location.id"
            :location="location"
            :index="index"
          ></PointSingle>
        </v-list>
        <v-btn
          style="float: right; margin-top: 10px; margin-bottom: 10px"
          @click="deleteDay"
          color="error"
          text
        >
          <i class="fal fa-trash" style="margin-right: 5px"></i> Delete this day
        </v-btn>
        <v-btn
          style="float: right; margin-top: 10px; margin-bottom: 10px"
          @click="switchView"
          color="default"
          text
        >
          <i
            class="fal"
            :class="
              currentView == 'grouped' ? 'fa-map-marker-alt' : 'fa-layer-group'
            "
            style="margin-right: 5px"
          ></i>
          Switch to {{ currentView == "points" ? "Group View" : "Point View" }}
        </v-btn>
      </div>
      <v-dialog v-model="d_date_select" max-width="400">
        <v-card>
          <v-card-title class="headline">{{
            $t("today.select_date")
          }}</v-card-title>
          <v-card-text style="text-align: center">
            <v-date-picker
              :allowed-dates="allowedDates"
              :first-day-of-week="1"
              :picker-date.sync="pickerDate"
              :events="filled_dates"
              :value="programmatically_date"
              @change="selectDate"
            ></v-date-picker>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { storage } from "../schmucklicloud";
import Location from "../logic/Location";

import PointGroup from "../components/timeline/PointGroup";
import PointSingle from "../components/timeline/PointSingle";
import { Notifications } from "../notification";

import { gmapApi } from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
Vue.component("GmapCluster", GmapCluster);

export default {
  data() {
    return {
      date: new Date(),
      d_date_select: false,
      loaded: false,

      currentView: window.localStorage.getItem("timeline_view") || "grouped",

      locations: [],
      locations_filtered: [],
      path: [],

      pickerDate: null,
      filled_dates: [],
      already_crawled_months: [],

      l_day_deletion: false,
    };
  },
  mounted() {
    var current_date = this.$route.params.date;
    if (current_date !== undefined) {
      this.selectDate(current_date);
    }

    this.load();
  },
  computed: {
    google: gmapApi,
    map_zoom() {
      return this.getZoomLevel(this.path);
    },
    map_center() {
      return this.getCenterFromCoordinates(this.path);
    },
    today_date() {
      var today = new Date();
      return (
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 + "").padStart(2, "0") +
        "-" +
        (today.getDate() + "").padStart(2, "0")
      );
    },
    programmatically_date() {
      return (
        this.date.getFullYear() +
        "-" +
        (this.date.getMonth() + 1 + "").padStart(2, "0") +
        "-" +
        (this.date.getDate() + "").padStart(2, "0")
      );
    },
    view_date() {
      var months = [
        this.$t("general.month.january"),
        this.$t("general.month.february"),
        this.$t("general.month.march"),
        this.$t("general.month.april"),
        this.$t("general.month.may"),
        this.$t("general.month.june"),
        this.$t("general.month.july"),
        this.$t("general.month.august"),
        this.$t("general.month.september"),
        this.$t("general.month.october"),
        this.$t("general.month.november"),
        this.$t("general.month.december"),
      ];

      var day_of_week = [
        this.$t("general.day_of_week.sunday"),
        this.$t("general.day_of_week.monday"),
        this.$t("general.day_of_week.tuesday"),
        this.$t("general.day_of_week.wednesday"),
        this.$t("general.day_of_week.thursday"),
        this.$t("general.day_of_week.friday"),
        this.$t("general.day_of_week.saturday"),
      ];

      return (
        day_of_week[this.date.getDay()] +
        ", " +
        this.date.getDate() +
        ". " +
        months[this.date.getMonth()] +
        " " +
        this.date.getFullYear()
      );
    },
    pointLocations() {
      var locations = [];

      for (let location of this.locations) {
        locations.push({
          id: location.id,
          location: {
            lat: parseFloat(location.data.lat),
            lng: parseFloat(location.data.lon),
          },
          weight: 100,
        });
      }
      return locations;
    },
  },
  methods: {
    dayBack() {
      this.date.setDate(this.date.getDate() - 1);
      this.date = new Date(this.date);

      this.load();
    },
    dayForward() {
      this.date.setDate(this.date.getDate() + 1);
      this.date = new Date(this.date);

      this.load();
    },
    async load() {
      this.resetView();
      var response = await Location.get(this.programmatically_date);

      if (response.isOK) {
        if (response.data !== undefined) {
          this.locations = response.data;
          if (this.locations != undefined) {
            this.locations.forEach(
              function (location, index) {
                this.locations[index].items = [];
                this.path.push({
                  lat: parseFloat(location.data.lat),
                  lng: parseFloat(location.data.lon),
                });
              }.bind(this)
            );

            var last_visible_index;
            this.locations.forEach(
              function (location, index) {
                if (index === 0) {
                  last_visible_index = 0;
                  return false;
                }

                var distance = this.getDistance(
                  location.data.lat,
                  location.data.lon,
                  this.locations[index - 1].data.lat,
                  this.locations[index - 1].data.lon
                );

                if (distance < 200) {
                  this.locations[index]["grouped"] = true;
                  this.locations[last_visible_index]["items"].push(
                    this.locations[index]
                  );
                } else {
                  last_visible_index = index;
                }
              }.bind(this)
            );

            this.locations_filtered = this.locations.filter(function (el) {
              return !el.grouped;
            });

            this.path = new Array(this.path)[0];
            this.locations_group = new Array(this.locations_group)[0];
          }
        } else {
          this.locations = [];
        }
      }
      this.loaded = true;
    },
    selectDate(date) {
      this.date = new Date(date);
      this.d_date_select = false;

      this.load();
    },
    // Formatters
    allowedDates(val) {
      var date = new Date(val);
      return new Date() - date > 0;
    },
    getDistance(lat1, lon1, lat2, lon2) {
      const R = 6371e3; // metres
      const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
      const φ2 = (lat2 * Math.PI) / 180;
      const Δφ = ((lat2 - lat1) * Math.PI) / 180;
      const Δλ = ((lon2 - lon1) * Math.PI) / 180;

      const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const d = R * c; // in metres
      return d;
    },
    getCenterFromCoordinates(coordinates) {
      var lats = 0,
        lngs = 0;
      coordinates.forEach(function (curr) {
        lats += curr.lat;
        lngs += curr.lng;
      });
      return { lat: lats / coordinates.length, lng: lngs / coordinates.length };
    },
    getZoomLevel(coordinates) {
      var GLOBE_WIDTH = 256;
      var pixelWidth = window.innerWidth / 3;
      var pixelHeight = 300;
      var west = 180,
        east = -180,
        north = 180,
        south = -180;
      coordinates.forEach(function (curr) {
        if (curr.lng < west) {
          west = curr.lng;
        }
        if (curr.lng > east) {
          east = curr.lng;
        }

        if (curr.lat < north) {
          north = curr.lat;
        }
        if (curr.lat > south) {
          south = curr.lat;
        }
      });
      var angle = east - west;
      if (angle < 0) {
        angle += 360;
      }

      var angle2 = south - north;
      if (angle2 < 0) {
        angle2 += 360;
      }

      return (
        Math.round(
          (Math.round(
            Math.log((pixelWidth * 360) / angle / GLOBE_WIDTH) / Math.LN2
          ) +
            Math.round(
              Math.log((pixelHeight * 360) / angle2 / GLOBE_WIDTH) / Math.LN2
            )) /
            2
        ) - 2
      );
    },
    resetView() {
      this.loaded = false;
      this.path = [];
      this.locations = [];
    },

    switchView() {
      if (this.currentView == "grouped") {
        this.currentView = "points";
      } else {
        this.currentView = "grouped";
      }

      window.localStorage.setItem("timeline_view", this.currentView);
    },

    async deleteDay() {
      if (confirm("Do you really want to delete this day?")) {
        this.l_day_deletion = true;
        var response = await storage.get("locations", [
          {
            column: "date",
            operator: "==",
            value: this.programmatically_date,
          },
        ]);

        if (response.isOK) {
          var deleted_points = 0;
          response.data.forEach(
            async function (position) {
              var current_id = position.id;
              var delete_response = await storage.delete(
                "locations",
                current_id
              );
              if (delete_response.isOK) {
                deleted_points++;
                if (deleted_points === response.data.length) {
                  this.l_day_deletion = false;
                  Notifications.show("The day timeline has been deleted.");
                  this.locations = [];
                  setTimeout(
                    function () {
                      this.load();
                    }.bind(this),
                    1000
                  );
                }
              } else {
                this.l_day_deletion = false;
                Notifications.show(
                  "There was an error while deleting a point."
                );
              }
            }.bind(this)
          );
        } else {
          this.l_day_deletion = false;
          Notifications.show(
            "There was an error while trying to fetch the locations to delete."
          );
        }
      }
    },
  },
  components: {
    PointGroup,
    PointSingle,
  },
};
</script>

<style>
.empty_map {
  height: 400px;
  width: calc(100% + 2 * 12px);
  margin: -12px;
  background: grey;
}
</style>
