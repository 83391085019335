import { storage } from "../schmucklicloud";

const Location = {
    get: async function(date) {
        var response = await storage.get("locations", [
            {
                column: "date",
                operator: "==",
                value: date
            }
        ], {
            column: "time",
            order: "asc"
        });

        return response;
    },

    count: async function(date) {
        var response = await storage.count("locations", [
            {
                column: "date",
                operator: "==",
                value: date
            }
        ], {
            column: "time",
            order: "asc"
        });

        return {response: response, date: date};
    }
};

export default Location;
