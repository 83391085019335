<template>
  <v-list-item>
      <v-list-item-content>
    <v-list-item-title>{{ locationTime }}</v-list-item-title>
    <v-list-item-subtitle>{{ location.data.address }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    locationTime() {
      var start_time = this.parseTime(this.location.data.time);

      return start_time;
    },
  },
  methods: {
    parseTime(timestamp) {
      var date = new Date(parseInt(timestamp) * 1000);
      return (
        (date.getHours() + "").padStart(2, "0") +
        ":" +
        (date.getMinutes() + "").padStart(2, "0")
      );
    },
  },
};
</script>

<style>
</style>